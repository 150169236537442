export const environment = {
  production: true,
  feUrl: 'https://platform.madsense.io',
  serviceUrl: 'https://social-api.madsense.io/api',
  madBuyServiceUrl: 'https://madbuy-be.madsense.io/api',
  madBuyReportingServiceUrl: 'https://madbuy-reporting.madsense.io/api',
  metaConfigId: '1220257238941913',
  metaAdsConfigId: '1521236798750152',
  metaInstagramConfigId: '792833459422160',
  metaAppId: '382794467576892',
  metaConfigId_2: '1584328929118107',
  metaAdsConfigId_2: '2082356485555226',
  metaInstagramConfigId_2: '974442101115637',
  metaAppId_2: '1590998431624896',
  client_id: '781k0u4pah1tr5', // Used for LinkedIn
  meta_company_id: [
    'dd3a9104-68e8-49d1-9d34-097fa615a367',
    '15df74d8-3e4c-415e-a5eb-2a1396661949',
    'a4972997-a231-4b9a-b9ee-f7402b966532',
    'd5341ade-eb74-4030-865a-d46835473938',
    '589a0d91-8f61-4777-9cc8-7807e00c84ad',
    '07e98de0-63a7-4bba-b919-53e28af4b43d',
    '9b0d10d5-0849-40b8-969d-06767bc0eb00',
    '1169930c-d46f-4052-89a2-50afd1fa7025',
    '0a73a87f-177b-4dca-a260-2fed8627be3e'
  ],
  redirect_uri:
    'https://social-api.madsense.io/api/social-media-connections/linkedin', // Replace with your actual redirect URI, Used for LinkedIn
  scope: 'w_member_social%2copenid%2cemail%2cprofile', // Define the scope of the access you are requesting, Used for LinkedIn
  sentryDsn: 'https://e2c9fdbe13f7335f833a9996f386f035@o4507844150427648.ingest.us.sentry.io/4507866426638336'
};
